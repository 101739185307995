<template>
  <div class="BuyCreditsConfirm">
    <Spacer num="1" />
    <div class="title">{{ $locale["credits_package_selected"] }}</div>
    <UserCreditsSuggest :item="item" :isConfirm="isConfirm" />
    <Spacer num="2" />
    <div class="options">
      <Confirm :confirm="confirm" @cancel="modal && modal.close()" @accept="goToPayPackage(item)" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "modal", "isConfirm"],
  components: {
    UserCreditsSuggest: () => import("../user/UserCreditsSuggest.vue"),
  },
  computed: {
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
        },
        accept: {
          label: this.$locale["check_out"],
        },
      };
    },
  },
};
</script>

<style lang="scss">
.BuyCreditsConfirm {
  margin: 0 0 0 0;
  background: var(--card-bg);
  padding: 0 $mpadding $mpadding $mpadding;
  border-radius: $mpadding;

  .title {
    font-weight: bold;
  }

  .credits-suugest {
    margin: $mpadding 0 0 0;
    pointer-events: none;
    border: solid 1px $alto;
    .radio {
      display: none;
    }
  }

  .pay-method {
    position: relative;
  }

  .options {
    text-align: center;
  }
}
</style>
